import * as React from 'react'
import { Component } from 'react';
import * as moment from 'moment';
import { Form, DatePicker, Modal, Table, Select, Input } from "antd-min";
import { GLGlobal, GLForm, GLFormProps, FormHelper, FormItemsLayout, alignPop, LanguageDateFormat } from 'gl-commonui';
import { GSAdminLocale, SchoolLocale } from '@app/locales/localeid';
import { fmtMsg, DateHelper, CommonHelper, isPastDate, rangeDateValiderEx } from '@app/util/index';
import { SubmitBtns } from '@app/components';
import { FormattedMessage } from 'react-intl';

interface GenerateUnitPlanProps extends GLFormProps {
    visible: boolean
    schoolClass: any
    unitPlans: any[]
    unitPlanIsModified: boolean
    displayClassName?: boolean
    onSave?: ({startDate, endDate})=> void
    onCancel?: ()=> void
}

interface GenerateUnitPlanState {
    startUnit?: any
}

@GLForm.create()
export class GenerateUnitPlan extends React.Component<GenerateUnitPlanProps, GenerateUnitPlanState> {
    private defaultStartUnit?: number
    private validUnitPlans?: any[]
    constructor(props) {
        super(props);
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.validUnitPlans = [];
        this.state = { };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.unitPlanIsModified) {
            this.getUnitPlans4Generate(nextProps.unitPlans);
        }
        if (!this.props.visible && nextProps.visible && nextProps.unitPlans && nextProps.unitPlans.length > 0) {
            this.setState({
                startUnit: this.getStartUnit(nextProps.unitPlans)
            })
        }
    }

    unitPlanIsEmpty(unitPlans) {
        return unitPlans.filter(plan => plan.startDate).length == 0;
    }
    
    getUnitPlans4Generate(unitPlans) {
        const unitPlan = this.getStartUnit(unitPlans);
        if (this.unitPlanIsEmpty(unitPlans)) {
            this.validUnitPlans = unitPlans && unitPlans.filter((plan, index) => index == 0);
        }
        else {
            const candidatePlans = unitPlans && unitPlans.filter(plan => unitPlan ? plan.unit >= unitPlan.unit : false);
            this.validUnitPlans = [];
            if (candidatePlans) {
                for (var index = candidatePlans.length - 1; index >= 0; index--) 
                {
                    if (index == 0 || candidatePlans[index - 1].startDate) {
                        this.validUnitPlans.unshift(candidatePlans[index]);
                    }
                }
            }
        }
        return this.validUnitPlans;
    }

    getStartUnit(unitPlans) {
        const unitPlans4Passed = unitPlans.filter(plan => plan.startDate && isPastDate(plan.startDate));
        const unitPlan4LatestPassed = unitPlans4Passed.length == 0 ? null : unitPlans4Passed[unitPlans4Passed.length - 1];
        const startUnit = unitPlan4LatestPassed && unitPlans.find(plan => plan.unit == unitPlan4LatestPassed.unit + 1 );
        if (!startUnit && !this.unitPlanIsEmpty(unitPlans) && unitPlans4Passed.length == 0 && unitPlans && unitPlans.length > 0) { return unitPlans[0]; }
        return startUnit;
    }

    getStartUnitOptions(unitPlans) {
        return unitPlans && this.getUnitPlans4Generate(unitPlans).map((unitPlan, index)=> {
            return CommonHelper.getOption(index, unitPlan.unit, unitPlan.unit)
        });
    }

    onStartUnitChanged(unit) {
        const unitPlan = this.props.unitPlans.find(unitPlan => unitPlan.unit == unit);
        this.setState({
            startUnit: unitPlan
        });
    }

    getStartUnit4Generate(unitPlans) {
        return this.state.startUnit && unitPlans.find(plan => plan.unit == this.state.startUnit.unit - 1);
    }

    getStartDate() {
        const {schoolClass, unitPlans} = this.props;
        const startUnit = this.getStartUnit4Generate(unitPlans);
        return startUnit && startUnit.startDate ? startUnit.startDate : schoolClass.startDate;
    }

    getEndDate() {
        return this.props.form.getFieldValue('endDate');
    }

    onSave(e) {
        const {form, schoolClass, unitPlans} = this.props;
        e.preventDefault();
        form.validateFields((err, values) => {
            if (!err) {
                const { onSave } = this.props;
                const startUnit = this.getStartUnit4Generate(unitPlans);
                if (onSave) { 
                    onSave({
                        schoolClassId: schoolClass.id,
                        ...values, 
                        beginUnit: startUnit && startUnit.unit,
                        startDate: startUnit && startUnit.startDate ? startUnit.startDate : schoolClass.startDate
                    }); 
                }
            }
        });
    }

    onCancel() {
        const { onCancel } = this.props;
        if (onCancel) { onCancel() };
    }

    render() {
        const {form, unitPlans, schoolClass: {startDate, endDate, maxUnit, name}, displayClassName} = this.props;
        const { renderFormItem } = FormHelper;
        const dateValider = rangeDateValiderEx(form, this.getStartDate.bind(this), this.getEndDate.bind(this), 'startDate', 'endDate', SchoolLocale.UnitPlanGenerateErrorDate);
        const unitOptions = this.getStartUnitOptions(unitPlans);
        const defaultUnit = this.validUnitPlans.length > 0 ? this.validUnitPlans[0].unit : null;
        const defaultStartDate = (this.state.startUnit && this.state.startUnit.startDate) || (this.unitPlanIsEmpty(unitPlans) && startDate) || null;
        return (
            <Modal
                title = {fmtMsg({id:SchoolLocale.UnitPlanGenerationTitle})}
                closable={false}
                footer={null}
                className={'generate-unitplan-modal'}
                width={'620px'}
                visible = { this.props.visible }
                destroyOnClose = { true }
            >
                <div className='generate-unitplan-modal-body'>
                    {displayClassName && 
                    <div className='generate-unitplan-modal-body-class-info'>
                        <div className='label-text'>
                            <div className='label-text-lable-with-colon'>
                                <span>{fmtMsg({id: SchoolLocale.ClassName})}</span>
                            </div>          
                            <div className='label-text-label-with-text'>
                                <span>{name}</span>
                            </div>
                        </div>                        
                    </div>}
                    <div className='generate-unitplan-modal-body-unit-info'>
                        <div className='generate-unitplan-modal-body-unit-info-left'>
                            <Table
                                rowKey='unit'
                                pagination={false}
                                scroll={{y: 200}}
                                dataSource={unitPlans}
                                columns={[
                                    {
                                        title: fmtMsg({ id: SchoolLocale.UnitPlanName }),
                                        dataIndex: "unit",
                                        width: '30%'
                                    }, {
                                        title: fmtMsg({ id: SchoolLocale.UnitPlanStartDate }),
                                        dataIndex: "startDate",
                                        width: '70%',
                                        render: (text, { startDate }) => startDate ? DateHelper.formatDate2Local(startDate) : ''
                                }]}
                            />
                        </div>
                        <GLForm form={form} onSubmit={this.onSave} className='generate-unitplan-modal-body-unit-info-right'>
                            <FormItemsLayout colTotal={1}>
                                {renderFormItem(form, SchoolLocale.UnitPlanUnit2Generate, 'startUnit', 
                                    <div className='unit2generate'>
                                        <Select size="large" defaultValue={defaultUnit} onChange={this.onStartUnitChanged.bind(this)} >{unitOptions}</Select>
                                        <span className='unit-range-separator'><FormattedMessage id={SchoolLocale.UnitPlanUnitsRangeSeparator} /></span>
                                        <Input type="text" value={Math.abs(maxUnit)} disabled />
                                    </div>)
                                }
                                {renderFormItem(form, SchoolLocale.ClassNewStartDate, 'startDate', <DatePicker placeholder={''} disabled={true}  {...alignPop({ type: 'DatePicker' })} format={LanguageDateFormat[GLGlobal.intl.locale]} />, defaultStartDate ? moment(defaultStartDate) : null, false)}
                                {renderFormItem(form, SchoolLocale.UnitPlanClassEndDate, 'endDate', <DatePicker  {...alignPop({ type: 'DatePicker' })} format={LanguageDateFormat[GLGlobal.intl.locale]} disabled={endDate != null} />, endDate ? moment(endDate) : null, endDate == null, dateValider)}
                            </FormItemsLayout>
                            <Form.Item>
                                <SubmitBtns submitTitle={GSAdminLocale.ButtonOk} isDisabled={defaultUnit == null} onCancel={this.onCancel} />
                            </Form.Item>
                        </GLForm>                        
                    </div>
                </div>
            </Modal>
        )
    }
}
