import * as React from 'react'
import { Component } from 'react';
import * as moment from 'moment';
import { Form, Select, Modal } from "antd-min";
import { GLGlobal, GLForm, GLFormProps, FormHelper, FormItemsLayout, alignPop, LanguageDateFormat } from 'gl-commonui';
import { GSAdminLocale, SchoolLocale } from '@app/locales/localeid';
import { fmtMsg, rangeDateValider, lazyInject, TYPES } from '@app/util/index';
import { SubmitBtns } from '@app/components';
import { ISchoolClassService, SchoolClassModel } from '@app/service/class';

interface CpClassUnitPlansProps extends GLFormProps {
    filterParams: SchoolClassModel
    visible: boolean
    onSave?: (d) => void
    onCancel?: () => void
}
type ClassPlan = { id: string, name: string, plans: { unit: number, startDate: string }[] }
interface CpClassUnitPlansStates {
    classes: ClassPlan[]
    selected: any
}

@GLForm.create()
export class CpClassUnitPlans extends React.Component<CpClassUnitPlansProps, CpClassUnitPlansStates> {
    @lazyInject(TYPES.ISchoolClassService)
    schoolClassService: ISchoolClassService
    constructor(props) {
        super(props);
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.state = {
            classes: [],
            selected: null
        };
    }

    componentWillReceiveProps(nextProps: CpClassUnitPlansProps) {
        if (this.props.visible !== nextProps.visible && nextProps.visible) {
            this.schoolClassService.getClassesForUnitPlanCopy({
                schoolId: nextProps.filterParams.schoolId,
                campusId: nextProps.filterParams.campusId,
                licenseType: nextProps.filterParams.curriculumType,
            }).then((classes: ClassPlan[]) => {
                classes = classes.filter(c => c.id !== nextProps.filterParams.id);
                this.setState({ classes, selected: classes[0].id });
            })
        }
    }

    onSave(e) {
        const { form } = this.props;
        e.preventDefault();
        form.validateFields((err, values) => {
            if (!err) {
                const { onSave } = this.props;
                if (onSave && values.classes) { onSave(this.state.classes.find(c => c.id === values.classes).plans) }
            }
        });
    }

    onCancel() {
        const { onCancel } = this.props;
        if (onCancel) { onCancel() };
    }

    render() {
        const { form } = this.props;
        const { classes, selected } = this.state;
        const { renderFormItem } = FormHelper;
        return (
            <Modal
                title={fmtMsg({ id: SchoolLocale.UnitPlanCopyTitle })}
                closable={false}
                footer={null}
                className={'localproduct-modal'}
                visible={this.props.visible}
                destroyOnClose={true}
            >
                <GLForm form={form} onSubmit={this.onSave}>
                    {renderFormItem(
                        form,
                        SchoolLocale.ClassName,
                        'classes',
                        <Select >
                            {classes.map(c => <Select.Option key={c.id} value={c.id} title={c.name}>{c.name}</Select.Option>)}
                        </Select>,
                        selected,
                        true
                    )}
                    <Form.Item>
                        <SubmitBtns submitTitle={GSAdminLocale.ButtonOk} onCancel={this.onCancel} />
                    </Form.Item>
                </GLForm>
            </Modal>
        )
    }
}