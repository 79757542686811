import * as React from "react";
import { Component } from "react";
import { FormattedMessage } from "react-intl";
import uniq from "lodash/uniq";
import maxBy from "lodash/maxBy";
import { SchoolLocale } from "@app/locales/localeid";
import {
    Table,
    DatePicker,
    Form,
    Tooltip,
    Dropdown,
    Menu,
    Switch,
    InputNumber
} from "antd-min";
import {
    GLGlobal,
    GLForm,
    GLFormComponentProps,
    connect,
    LanguageDateFormat,
    NotificationType,
    MessageHelper,
    RoleName, GLUtil
} from "gl-commonui";
import { SecondaryTitle, SubmitBtns } from "@app/components";
import { StateType } from "@app/states";
import {
    getTableProps,
    fmtMsg,
    sliceMoment,
    isPastDate,
    isFutureDate,
    isNotBetweenDate
} from "@app/util/func";
import {
    GSSchoolAction,
    Weekday4Backend,
    Weekday4UnitPlan,
    SchoolClassTimeType,
    AppLockState
} from "@app/util/enum";
import {
    get,
    getUnitPlans,
    goToStudents,
    updateUnitPlans,
    generateUnitPlans,
    cpUnitPlans
} from "@app/states/school/class";
import { SchoolModel } from "@app/service/schools/models";
import { get as getSchool } from "@app/states/school/school";
import * as moment from "moment";
import { CurriculumType, SchoolClassModel } from "@app/service/class";
import { GenerateUnitPlan } from "./generate-unit-plan";
import { CpClassUnitPlans } from "./cp-classes";
import { ClassHelper, CONSTS, isInternalUser, isOnlySpecificRole, lazyInject, TYPES } from "@app/util";
import { UnitPlanHistory } from "./unitplan-history";
import { IRegionService } from "@app/service/admin/regions";
import { SchoolPathConfig } from "@app/config/pathconfig";

interface UnitPlanProps {
    autoFillUnitPlans?: number;
    unitPlans?: any[];
    progressionHistories?: any[];
    model?: any;
    school?: any;
    futureAnnualOuter?: boolean;
    goToStudents?: () => void;
    updateUnitPlans?: (d) => void;
    generateUnitPlans?: (d) => void;
    cpUnitPlans?: (d) => void;
    regionId: string;
    schoolId: string;
    campusId: string;
    classId: string;
    isPopup?: boolean;
    onCancel?: () => void;
    onSubmit?: () => void;
}

interface UnitPlanStates {
    isNextBtnDisabled?: boolean;
    isGeneratePlanVisible?: boolean;
    isPlanModified?: boolean;
    isCpClassesVisible?: boolean;
    isUnitPlanHistoryVisible?: boolean;
    enforceUnitPlanSpacing: boolean;
}

@GLForm.create()
@connect(
    ({
         schoolClass: {
             unitPlans,
             progressionHistories,
             model,
             autoFillUnitPlans,
             futureAnnualOuter
         },
         school: { current: school },
     }: StateType) => ({
        unitPlans,
        progressionHistories,
        model,
        autoFillUnitPlans,
        futureAnnualOuter,
        school
    }),
    {
        goToStudents,
        updateUnitPlans,
        generateUnitPlans,
        cpUnitPlans
    },
    ({
         state: { unitPlans, model },
         dispatch: { updateUnitPlans },
         own: { regionId, schoolId, campusId, classId, isPopup, onSubmit },
     }) => ({
        updateUnitPlans: updateUnitPlansBy(
            regionId,
            schoolId,
            campusId,
            classId,
            isPopup,
            updateUnitPlans,
            onSubmit
        ),
        model: { unitPlans, model }
    })
)
export class UnitPlan extends Component<
    UnitPlanProps & GLFormComponentProps,
    UnitPlanStates
> {
    @lazyInject(TYPES.IRegionService)
    service: IRegionService;

    submitTitle;
    constructor(props) {
        super(props);
        const obj = JSON.parse(sessionStorage.getItem("isCreatedClass"));
        this.state = {
            isNextBtnDisabled: false, // obj && obj.isCreatedClass,
            isGeneratePlanVisible: false,
            isPlanModified: false,
            isCpClassesVisible: false,
            isUnitPlanHistoryVisible: false,
            enforceUnitPlanSpacing: true,
        };
        this.submitTitle =
            obj && obj.isCreatedClass ? SchoolLocale.InvitationNext : null;
        this.onGenerateUnitPlans = this.onGenerateUnitPlans.bind(this);
        this.generateUnitPlans = this.generateUnitPlans.bind(this);
        this.closeEndDateEditor = this.closeEndDateEditor.bind(this);
        this.setNextBtnEnabled = this.setNextBtnEnabled.bind(this);
        this.onLoadUnitPlanHistory = this.onLoadUnitPlanHistory.bind(this);
    }
    componentWillMount() {
        this.service
            .getEnforceUnitPlanSpacing(this.props.regionId)
            .then(status => {
                this.setState({ enforceUnitPlanSpacing: status });
            })
            .catch(error => {
                //console.log(error);
            });
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.autoFillUnitPlans !== nextProps.autoFillUnitPlans) {
            try {
                validater(valider, () => this.forceUpdate(), this.state.enforceUnitPlanSpacing)(
                    {},
                    nextProps.model
                );
            } catch (error) {
                console.log(error);
            }
        }
    }
    setNextBtnEnabled(isDisabled) {
        //alway enable next button
        //this.setState({ isNextBtnDisabled: isDisabled });
    }
    onGenerateUnitPlans() {
        const { unitPlans } = this.props;
        //reset error first
        resetUnitPlanStartDateEmptyError(unitPlans);
        const errorPlans = unitPlans.filter(
            plan => plan.error && plan.error.startDate
        );
        if (errorPlans.length > 0) {
            MessageHelper.Message(
                NotificationType.Warning,
                fmtMsg({ id: SchoolLocale.UnitPlanGenerateErrorPlanData })
            );
        } else {
            this.setState({ isGeneratePlanVisible: true });
        }
    }
    getUnitPlanStartDate({ model, unitPlans }) {
        const { startDate: classStartDate } = model;
        const unitPlans4StartDate = unitPlans.filter(
            plan => plan.startDate && isPastDate(plan.startDate)
        );
        return unitPlans4StartDate.length == 0
            ? classStartDate
            : unitPlans4StartDate[unitPlans4StartDate.length - 1].startDate;
    }
    generateUnitPlans({ beginUnit, startDate, endDate }) {
        const {
            model: { model },
            unitPlans,
            generateUnitPlans
        } = this.props;
        const [validMinDaysPerUnit, suggestMinDaysPerUnit] = getMinDaysPerUnit(
            model, this.state.enforceUnitPlanSpacing
        );
        const daysofweek = uniq(
            model.schoolClassTime
                .filter(
                    sct =>
                        sct.type ==
                        SchoolClassTimeType[SchoolClassTimeType.TSI] &&
                        sct.daysOfWeek != Weekday4Backend.Sunday &&
                        sct.daysOfWeek != Weekday4Backend.Saturday
                )
                .map(sct => sct.daysOfWeek - 1)
        );
        generateUnitPlans &&
        generateUnitPlans({
            beginUnit,
            startDate,
            endDate,
            unitPlans,
            minDaysPerUnit: suggestMinDaysPerUnit,
            daysofweek:
                daysofweek.length == 0
                    ? Object.keys(Weekday4UnitPlan)
                        .filter(day => day.length == 1)
                        .map(day => parseInt(day))
                    : daysofweek,
            successCallback: () => {
                this.closeEndDateEditor();
                this.setNextBtnEnabled(false);
            }
        });
    }
    closeEndDateEditor() {
        if (this.state.isGeneratePlanVisible) {
            this.setState({ isGeneratePlanVisible: false });
        }
    }
    onLoadUnitPlanHistory(e) {
        e.preventDefault();
        this.setState({
            isUnitPlanHistoryVisible: !this.state.isUnitPlanHistoryVisible
        });
    }
    render() {
        const {
            schoolId,
            campusId,
            classId,
            regionId,
            goToStudents,
            updateUnitPlans,
            form,
            progressionHistories,
            model,
            school,
            unitPlans,
            futureAnnualOuter,
            isPopup,
            onCancel,
        } = this.props;
        const classDisabled = model.model.disabled;
        const params = { schoolId, campusId, classId, regionId };
        const unitPlanPagePath = GLUtil.pathStringify(SchoolPathConfig.ClassUnitPlan, params);
        return (
            <div className={`content-layout unitplan-crud ${isPopup ? 'unitplan-popup' : ''}`}>
                <div>
                    <GLForm
                        form={form}
                        onSubmit={e => {
                            e.preventDefault();
                            const enforce = this.state.enforceUnitPlanSpacing;
                            const format = validater(valider, this.forceUpdate.bind(this), enforce);
                            const {
                                props: { form, model }
                            } = this;
                            form.validateFields((err, values) => {
                                if (!err) {
                                    format(values, model);
                                    updateUnitPlans({ ...model, ...values });
                                }
                            });
                        }}
                    >
                        <SecondaryTitle title={SchoolLocale.UnitPlanTitle}>
                            {school.allowGenerateUnitPlan &&
                                !futureAnnualOuter && !isPopup && (
                                    <div className="generate-unit-plan">
                                        <Dropdown.Button
                                            trigger={["click"]}
                                            disabled={
                                                classDisabled ||
                                                isOnlySpecificRole(
                                                    RoleName.globalHead
                                                )
                                            }
                                            onClick={e => {
                                                e.preventDefault();
                                                this.onGenerateUnitPlans();
                                            }}
                                            overlay={
                                                <Menu
                                                    onClick={e => {
                                                        e.domEvent.preventDefault();
                                                        this.setState({
                                                            isCpClassesVisible: true
                                                        });
                                                    }}
                                                >
                                                    <Menu.Item key="cp">
                                                        <FormattedMessage
                                                            id={
                                                                SchoolLocale.UnitPlanCopy
                                                            }
                                                        />
                                                    </Menu.Item>
                                                </Menu>
                                            }
                                        >
                                            <FormattedMessage
                                                id={
                                                    SchoolLocale.UnitPlanGeneration
                                                }
                                            />
                                        </Dropdown.Button>
                                    </div>
                                )}
                        </SecondaryTitle>
                        <UnitsContent
                            {...{ schoolId, campusId, classId, regionId }}
                            enforceUnitPlanSpacing={this.state.enforceUnitPlanSpacing}
                            callback={d => this.setNextBtnEnabled(d)}
                            changedHandle={() =>
                                this.setState({ isPlanModified: true })
                            }
                        />
                        <CurrentUnit unitPath={isPopup ? unitPlanPagePath : undefined} />
                        {
                            !isPopup &&
                            <>
                                <LockedUnit />
                                <UnitPlanHistory
                                    visible={this.state.isUnitPlanHistoryVisible}
                                    progressionHistories={progressionHistories}
                                    routeParams={params}
                                    onLoadUnitPlanHistory={this.onLoadUnitPlanHistory}
                                />
                            </>
                        }
                        <SubmitBtns
                            isDisabled={this.state.isNextBtnDisabled}
                            hideSubmitButton={
                                futureAnnualOuter ||
                                classDisabled ||
                                isOnlySpecificRole(RoleName.globalHead)
                            }
                            submitTitle={this.submitTitle}
                            onCancel={() => {
                                sessionStorage.removeItem("isCreatedClass");
                                if (!isPopup) {
                                    goToStudents();
                                } else if (onCancel) {
                                    onCancel();
                                }
                            }}
                            submitAction={GSSchoolAction.SavePlan}
                        />
                    </GLForm>
                    {
                        !isPopup &&
                        <GenerateUnitPlan
                            visible={this.state.isGeneratePlanVisible}
                            unitPlans={unitPlans}
                            unitPlanIsModified={this.state.isPlanModified}
                            schoolClass={model.model}
                            onSave={this.generateUnitPlans}
                            onCancel={this.closeEndDateEditor}
                        />
                    }
                    <CpClassUnitPlans
                        visible={this.state.isCpClassesVisible}
                        filterParams={{ ...(model as any).model, schoolId }}
                        onSave={d => {
                            this.props.cpUnitPlans(
                                formatCopiedUnitPlans(
                                    d,
                                    (model as any).unitPlans
                                )
                            );
                            this.setState({ isCpClassesVisible: false });
                        }}
                        onCancel={() =>
                            this.setState({ isCpClassesVisible: false })
                        }
                    />
                </div>
            </div>
        );
    }
}
function formatCopiedUnitPlans(next: any[], pre: any[]) {
    return pre.map((p, index) => {
        if (index >= next.length) {
            return p;
        }
        if (
            sliceMoment(
                validDate({ date: () => p.startDate, defaultValue: moment() })
            ).diff(sliceMoment(moment())) < 0
        ) {
            return p;
        }
        const cpStartDate = next[index].startDate;
        if (!cpStartDate) {
            return p;
        }
        if (sliceMoment(cpStartDate).diff(sliceMoment(moment())) < 0) {
            return p;
        }
        p.startDate = cpStartDate;
        return p;
    });
}
function resetUnitPlanStartDateEmptyError(unitPlans) {
    //reset error first
    unitPlans.forEach(unitPlan => {
        if (
            unitPlan.error &&
            unitPlan.error.startDate &&
            !unitPlan.error["startDate-source"]
        ) {
            unitPlan.error["startDate"] = null;
        }
    });
}
function validater(valider, failedCallback, enforceUnitPlanSpacing) {
    return function(
        values,
        {
            unitPlans,
            model
        }: {
            unitPlans: {
                unit;
                startDate;
                unlockDate;
                error: { startDate; unlockDate };
            }[];
            model: { startDate };
        }
    ) {
        const [validMinDaysPerUnit, suggestMinDaysPerUnit] = getMinDaysPerUnit(model, enforceUnitPlanSpacing);
        let needRender = [];
        unitPlans.forEach(unit => {
            needRender.push(
                enforceUnitPlanSpacing && checkUnit(
                    unitPlans,
                    unit,
                    "startDate",
                    unit.startDate,
                    SchoolLocale.UnitPlanStartDate,
                    validMinDaysPerUnit
                )
            );
        });

        if (needRender.some(need => need)) {
            failedCallback();
        }

        //reset error first
        resetUnitPlanStartDateEmptyError(unitPlans);

        //check if there is blank unit plan before that last unit that has the start date
        const lastUnitPlan = maxBy(
            unitPlans.filter(plan => plan.startDate),
            "unit"
        );
        if (lastUnitPlan) {
            const invalidUnitPlans = unitPlans.filter(
                plan => plan.unit < lastUnitPlan.unit && !plan.startDate
            );
            invalidUnitPlans.forEach(unitPlan => {
                unitPlan.error["startDate"] = formatMessage => {
                    return formatMessage(
                        { id: SchoolLocale.UnitPlanValidStartDateEmpty },
                        { unit: unitPlan.unit }
                    );
                };
            });
            if (invalidUnitPlans.length > 0) {
                failedCallback();
            }
        }

        if (
            (unitPlans && unitPlans.length == 0) ||
            (unitPlans && unitPlans.length > 0 && !unitPlans[0].startDate)
        ) {
            const unitPlan = unitPlans[0];
            unitPlan.error = unitPlan.error || ({} as any);
            unitPlan.error["startDate"] = formatMessage => {
                return formatMessage(
                    { id: SchoolLocale.UnitPlanValidStartDateEmpty },
                    { unit: unitPlan.unit }
                );
            };
            failedCallback();
        }

        valider(values, { unitPlans, model });
    };
}
function valider(
    values,
    {
        unitPlans,
        model
    }: {
        unitPlans: {
            unit;
            startDate;
            unlockDate;
            error: { startDate; unlockDate };
        }[];
        model: { startDate };
    }
) {
    const units = unitPlans.filter(
        unit =>
            unit.error && (unit.error["startDate"] || unit.error["unlockDate"])
    );
    if (units.length > 0) {
        if (typeof units[0].error.startDate === "function") {
            throw `${units[0].error.startDate(fmtMsg)}\n${
                units[0].error.unlockDate
            }`;
        }
        throw `${units[0].error.startDate}\n${units[0].error.unlockDate}`;
    }
    // const hasFilledUnit = unitPlans.some(unit => validDate({ date: () => unit.startDate }));
    // if (!hasFilledUnit) {
    //     throw MessageHelper.Message(NotificationType.Warning, fmtMsg({ id: SchoolLocale.UnitPlanValidStartAndUnlockDate }));
    // }
}

function updateUnitPlansBy(
    regionId,
    schoolId,
    campusId,
    classId,
    isPopup,
    updateUnitPlans,
    onSubmit,
) {
    const redirectToStudents = !isPopup;
    return () => updateUnitPlans({ regionId, schoolId, campusId, classId, redirectToStudents, onSubmit });
}

interface UnitsContentProps {
    dataSource?: any;
    loading?: boolean;
    school?: SchoolModel;
    schoolClass?: SchoolClassModel;
    schoolId: string;
    campusId: string;
    classId: string;
    regionId: string;
    futureAnnualOuter?: boolean;
    enforceUnitPlanSpacing: boolean;
    getSchool?: (d) => void;
    get?: (d) => void;
    getUnitPlans?: (d) => void;
    changedHandle?: () => void;
    callback?: (d) => void;
}

class AdminsTable extends Table<any> {}

@connect(
    ({
         school: { current },
         schoolClass: { unitPlans, loading, model, futureAnnualOuter }
     }: StateType) => ({
        school: current,
        schoolClass: model,
        dataSource: unitPlans,
        loading,
        futureAnnualOuter
    }),
    {
        getSchool,
        get,
        getUnitPlans
    }
)
class UnitsContent extends Component<UnitsContentProps> {
    startDate;
    unlockDate;
    constructor(props) {
        super(props);
        this.reRenderHandler = this.reRenderHandler.bind(this);
    }
    reRenderHandler() {
        const { changedHandle } = this.props;
        this.forceUpdate();
        changedHandle && changedHandle();
    }
    componentWillMount() {
        const {
            getSchool,
            get,
            getUnitPlans,
            schoolId,
            campusId,
            classId,
            regionId
        } = this.props;
        getUnitPlans({ schoolId, campusId, classId });
    }
    validateDateField(value, field) {
        const { callback } = this.props;
        const obj = JSON.parse(sessionStorage.getItem("isCreatedClass"));
        if (!obj || !obj.isCreatedClass) return;
        switch (field) {
            case "startDate":
                this.startDate = value;
                break;
            case "unlockDate":
                this.unlockDate = value;
                break;
        }
        if (this.startDate) {
            callback && callback(false);
        } else {
            callback && callback(true);
        }
    }
    render() {
        const {
            dataSource,
            loading,
            school,
            schoolClass,
            futureAnnualOuter,
            enforceUnitPlanSpacing
        } = this.props;
        const [validMinDaysPerUnit, suggestMinDaysPerUnit] = getMinDaysPerUnit(
            schoolClass, this.props.enforceUnitPlanSpacing
        );
        return (
            <div className="page-content">
                <AdminsTable
                    {...getTableProps({
                        rowKey: "unit",
                        dataSource,
                        loading,
                        columns: getColumns(
                            this.reRenderHandler,
                            dataSource,
                            {
                                ...school,
                                minDaysPerUnit: validMinDaysPerUnit,
                                suggestMinDaysPerUnit
                            },
                            this.validateDateField.bind(this),
                            schoolClass,
                            futureAnnualOuter,
                            enforceUnitPlanSpacing
                        )
                    })}
                ></AdminsTable>
            </div>
        );
    }
}

function getMinDaysPerUnit({
                               tsiLessonsPerWeek,
                               curriculumType
                           }: SchoolClassModel,
                           enforceUnitPlanSpacing?: boolean) {
    if (tsiLessonsPerWeek === undefined || curriculumType === undefined)
        return [1, 1];

    if (Number(curriculumType) === CurriculumType.LittleSEED) return [1, 1];

    const minDaysPerUnit = Math.ceil(210 / tsiLessonsPerWeek) - 4;
    return [
        (!GLGlobal.isActionValid(GSSchoolAction.UncheckMindaysPerUnit) && enforceUnitPlanSpacing === false) || GLGlobal.isActionValid(GSSchoolAction.UncheckMindaysPerUnit)
            ? 1
            : minDaysPerUnit,
        minDaysPerUnit
    ];
}

function getColumns(
    reRenderHandler,
    dataSource,
    school,
    validateDateField,
    schoolClass,
    futureAnnualOuter,
    enforceUnitPlanSpacing?
) {
    const disabled =
        futureAnnualOuter ||
        schoolClass.disabled ||
        isOnlySpecificRole(RoleName.globalHead);
    return [
        {
            title: fmtMsg({ id: SchoolLocale.UnitPlanName }),
            dataIndex: "unit",
            width: "10%"
        },
        {
            title: fmtMsg({ id: SchoolLocale.UnitPlanStartDate }),
            dataIndex: "startDate",
            width: "25%",
            className: "start-date-col",
            render: (text, record, index) => {
                const isPastPlan =
                    !GLGlobal.isActionValid(
                        GSSchoolAction.UncheckMindaysPerUnit
                    ) && isPastDate(record.startDate);
                const disabledTip = fmtMsg({
                    id: SchoolLocale.UnitPlanStartDateDisabled
                });
                const startDateIsDisabled = !record.editable && isPastPlan;
                return (
                    <Form.Item
                        validateStatus={
                            record.error && record.error["startDate"]
                                ? "error"
                                : "success"
                        }
                        className={
                            record.error && record.error["startDate"]
                                ? "unit-error-row"
                                : "unit-normal-row"
                        }
                        help={
                            record.error &&
                            record.error["startDate"] &&
                            record.error["startDate"](
                                GLGlobal.intl.formatMessage
                            )
                        }
                    >
                        <Tooltip
                            placement="top"
                            trigger="click"
                            title={startDateIsDisabled ? disabledTip : null}
                        >
                            <div className="ignore-datepicker-disabled-for-tooltip-showtip">
                                <DatePicker
                                    format={
                                        LanguageDateFormat[GLGlobal.intl.locale]
                                    }
                                    defaultPickerValue={getDefaultStartDate(
                                        dataSource,
                                        record.unit,
                                        school.suggestMinDaysPerUnit
                                    )}
                                    disabledDate={isNotBetweenDate(
                                        sliceMoment(moment(schoolClass.startDate)),
                                        sliceMoment(moment(schoolClass.endDate))
                                    )}
                                    defaultValue={validDate({
                                        date: () => record.startDate
                                    })}
                                    value={validDate({
                                        date: () => record.startDate
                                    })}
                                    disabled={startDateIsDisabled || disabled}
                                    onChange={value => {
                                        validateDateField(value, "startDate");
                                        onDateChange(
                                            sliceMoment(value),
                                            reRenderHandler,
                                            dataSource,
                                            record.unit,
                                            "startDate",
                                            SchoolLocale.UnitPlanStartDate,
                                            school.minDaysPerUnit,
                                            enforceUnitPlanSpacing
                                        );
                                        unitStartDateChanged(
                                            sliceMoment(value),
                                            reRenderHandler,
                                            dataSource,
                                            record.unit,
                                            school
                                        );
                                    }}
                                />
                            </div>
                        </Tooltip>
                    </Form.Item>
                );
            }
        },
        {
            title: fmtMsg({ id: SchoolLocale.UnitPlanAppUnlockState }),
            dataIndex: "lockState4Display",
            width: "25%",
            render: (text, record, index) => {
                if (!record.startDate) {
                    record.lockState = null;
                    record.lockState4Display = null;
                    return "";
                }
                const stateTextId = record.lockState4Display
                    ? SchoolLocale.UnitPlanAppUnlockStateLock
                    : SchoolLocale.UnitPlanAppUnlockStateUnlock;
                const switchDisabled =
                    record.appLockStateDisabled ||
                    disabled ||
                    !ClassHelper.canEditUnitUnlockState(schoolClass);
                return (
                    <div className="appLockState-col">
                        <Switch
                            checked={record.lockState4Display}
                            disabled={switchDisabled}
                            onChange={checked =>
                                unitLockModeChanged(
                                    checked,
                                    reRenderHandler,
                                    dataSource,
                                    record.unit,
                                    school
                                )
                            }
                        />
                        <span>{fmtMsg({ id: stateTextId })}</span>
                    </div>
                );
            }
        },
        {
            title: fmtMsg({ id: SchoolLocale.UnitPlanAppContentStatus }),
            dataIndex: "appContentStatus",
            width: "20%",
            render: (text, record, index) => {
                if (!record.startDate) return "";
                return record.appContentStatus
                    ? record.appContentStatus()
                    : text;
                // return validDate({
                //     date: () => record.startDate,
                //     format: (date) => DateHelper.formatDate2Local(moment(date).add(school.appUnlockDays, 'day')),
                //     defaultValue: ''
                // })
            }
        },
        {
            title: fmtMsg({ id: SchoolLocale.UnitPlanLessonNumber }),
            dataIndex: "lessonNumber",
            width: "20%",
            render: (text, record, index) => {
                if (!record.startDate) {
                    return null;
                }
                const disableLessonNUmber =
                    disabled ||
                    getLessonNumberDisableStatus(
                        record.lockState,
                        school.appUnlockDays,
                        record.startDate
                    );
                return (
                    <div className="col-lesson-number">
                        <InputNumber
                            value={record.lessonNumber}
                            min={0}
                            max={CONSTS.MAX_INTEGER_VALUE}
                            precision={0}
                            disabled={disableLessonNUmber}
                            onChange={value =>
                                setLessonNumber(
                                    value,
                                    reRenderHandler,
                                    dataSource,
                                    record.unit,
                                    school
                                )
                            }
                        />
                    </div>
                );
            }
        }
    ];
}
function setLessonNumber(value, reRenderHandler, dataSource, unit, index) {
    const cur = dataSource.find(item => item.unit == unit);
    if(cur) {
        cur.lessonNumber = value++;
    }

    reRenderHandler();
}
function getLessonNumberDisableStatus(appLockState: number, days2UnlockApp: number, startDate: string | moment.Moment) {
    const today = sliceMoment(moment(new Date()));
    const unlockAppDay = sliceMoment(moment(startDate).add(days2UnlockApp, 'days'));
    const pastUnlockAppDays = moment(today).diff(moment(unlockAppDay), 'days');
    const isExternalUser = !isInternalUser();
    if(isExternalUser){
        switch (appLockState) {
            case AppLockState.Unlock:
                return pastUnlockAppDays >= 0 ? false : true;
            case AppLockState.Lock:
            default:
                return true;
        }
    }
    else{
        return false;
    }
}
function validDate({
                       date = () => defaultValue,
                       valid = () => true,
                       format = d => d,
                       defaultValue = null
                   }: {
    date: () => moment.Moment;
    valid?: () => boolean;
    format?: (d) => any;
    defaultValue?: any;
}) {
    return valid() && date() && date().isValid()
        ? format(date())
        : defaultValue;
}
function onDateChange(
    value,
    reRenderHandler,
    dataSource,
    unit,
    dataIndex,
    localeDataIndex,
    minDaysPerUnit,
    enforceUnitPlanSpacing?,
    callback?
) {
    const cur = dataSource.find(item => item.unit == unit);
    if (!cur) {
        return;
    }
    let needRerender = enforceUnitPlanSpacing && checkUnit(
        dataSource,
        cur,
        dataIndex,
        value,
        localeDataIndex,
        minDaysPerUnit
    );
    cur[dataIndex] = value;
    //judge is error still exists in other unit;
    const errorUnits = dataSource.filter(
        item => item.error && item.startDate && item.startDate.isValid()
    );
    errorUnits &&
    errorUnits.forEach(errorUnit => {
        needRerender =
            enforceUnitPlanSpacing && checkUnit(
                dataSource,
                errorUnit,
                dataIndex,
                errorUnit[dataIndex],
                localeDataIndex,
                minDaysPerUnit
            ) || needRerender;
    });
    //check whether unlock date is greater than start date
    const comparer = {
        ">": function(a, b) {
            return a > b;
        },
        "<": function(a, b) {
            return a < b;
        }
    };
    const comparedDataIndex =
        dataIndex == "startDate" ? "unlockDate" : "startDate";
    const comparedOperator = dataIndex == "startDate" ? ">" : "<";
    const comparedMsg =
        dataIndex == "startDate"
            ? formatMessage => {
                return formatMessage(
                    { id: SchoolLocale.UnitPlanValidStartDate },
                    { cur: cur.unit }
                );
            }
            : formatMessage => {
                return formatMessage(
                    { id: SchoolLocale.UnitPlanValidUnlockDate },
                    { cur: cur.unit }
                );
            };
    if (validDate({ date: () => value })) {
        if (
            cur[comparedDataIndex] &&
            comparer[comparedOperator](
                cur[comparedDataIndex] && value.format("YYYYMMDD"),
                cur[comparedDataIndex].format("YYYYMMDD")
            )
        ) {
            if (!cur.error) {
                cur.error = {};
            }
            cur.error[dataIndex] = comparedMsg;
            cur.error[`${dataIndex}-source`] = comparedDataIndex;
            needRerender = true;
        } else if (cur.error) {
            if (cur.error[`${dataIndex}-source`] == comparedDataIndex) {
                cur.error[dataIndex] = null;
                cur.error[`${dataIndex}-source`] = null;
            }
            if (cur.error[`${comparedDataIndex}-source`] == dataIndex) {
                cur.error[comparedDataIndex] = null;
                cur.error[`${comparedDataIndex}-source`] = null;
            }
            needRerender = true;
        }
    } else if (dataIndex == "startDate") {
        //start date is null, should clear lock date and disable it
        cur[comparedDataIndex] = null;
        //reset error
        if (cur.error) {
            cur.error[dataIndex] = null;
            cur.error[`${dataIndex}-source`] = null;
            cur.error[comparedDataIndex] = null;
            cur.error[`${comparedDataIndex}-source`] = null;
        }
        needRerender = true;
    } else if (dataIndex == "unlockDate") {
        //start/lock date both null
        if (
            cur.error &&
            cur.error[`${dataIndex}-source`] == comparedDataIndex
        ) {
            cur.error[dataIndex] = null;
            cur.error[`${dataIndex}-source`] = null;
        }
        if (
            cur.error &&
            cur.error[`${comparedDataIndex}-source`] == dataIndex
        ) {
            cur.error[comparedDataIndex] = null;
            cur.error[`${comparedDataIndex}-source`] = null;
        }
        needRerender = true;
    }
    if (value) {
        needRerender = true;
    }
    if (needRerender) {
        reRenderHandler();
    }
}
function checkUnit(
    units,
    unit,
    dataIndex,
    dateValue,
    localeDataIndex,
    minDaysPerUnit
) {
    let needRerender = false;
    let preHasError = false;
    const validUnit = unit =>
        validDate({ valid: () => unit, date: () => unit[dataIndex] });
    //check previous unit
    const preUnits = units.filter(
        item =>
            item.unit < unit.unit &&
            item[dataIndex] &&
            item[dataIndex].isValid()
    );
    const pre = preUnits && preUnits[preUnits.length - 1];
    if (validUnit(pre)) {
        if (
            dateValue &&
            dateValue.format("YYYYMMDD") <= pre[dataIndex].format("YYYYMMDD")
        ) {
            const msg = formatMessage => {
                const field = formatMessage({
                    id: localeDataIndex
                }).toLowerCase();
                return formatMessage(
                    { id: SchoolLocale.UnitPlanValidEx },
                    { pre: pre.unit, date: field, cur: unit.unit }
                );
            };
            if (!unit.error) {
                unit.error = {};
            }
            //if error cause by other dataIndex, we should not replace it
            if (!unit.error[dataIndex]) {
                unit.error[dataIndex] = msg;
                unit.error[`${dataIndex}-source`] = dataIndex;
            }
            needRerender = true;
            preHasError = true;
        } else if (
            dataIndex == "startDate" &&
            minDaysPerUnit &&
            sliceMoment(moment(dateValue)).diff(
                sliceMoment(moment(pre[dataIndex])),
                "days"
            ) < minDaysPerUnit
        ) {
            const msg = formatMessage => {
                return formatMessage(
                    {
                        id:
                            minDaysPerUnit > 1
                                ? SchoolLocale.UnitPlanValidMinimumDays
                                : SchoolLocale.UnitPlanValidMinimumDay
                    },
                    { value: minDaysPerUnit }
                );
            };
            if (!unit.error) {
                unit.error = {};
            }
            // //if error cause by other dataIndex, we should not replace it
            // if (!unit.error[dataIndex]) {
            unit.error[dataIndex] = msg;
            unit.error[`${dataIndex}-source`] = dataIndex;
            // }
            needRerender = true;
            preHasError = true;
        } else {
            needRerender = unit.error && unit.error[dataIndex] != null;
            if (!unit.error) {
                unit.error = {};
            }
            if (unit.error[`${dataIndex}-source`] == dataIndex) {
                unit.error[dataIndex] = null;
                unit.error[`${dataIndex}-source`] = null;
            }
        }
    }
    //check next unit
    const nextUnits = units.filter(
        item => item.unit > unit.unit && item[dataIndex]
    );
    const next = nextUnits && nextUnits[0];
    if (validUnit(next)) {
        if (
            dateValue &&
            dateValue.format("YYYYMMDD") >= next[dataIndex].format("YYYYMMDD")
        ) {
            const msg = formatMessage => {
                const field = formatMessage({
                    id: localeDataIndex
                }).toLowerCase();
                return formatMessage(
                    { id: SchoolLocale.UnitPlanValid },
                    { pre: unit.unit, date: field, cur: next.unit }
                );
            };
            if (!unit.error) {
                unit.error = {};
            }
            //if error cause by other dataIndex, we should not replace it
            if (!unit.error[dataIndex]) {
                unit.error[dataIndex] = msg;
                unit.error[`${dataIndex}-source`] = dataIndex;
            }
            needRerender = true;
        } else if (
            dataIndex == "startDate" &&
            minDaysPerUnit &&
            sliceMoment(moment(next[dataIndex])).diff(
                sliceMoment(moment(dateValue)),
                "days"
            ) < minDaysPerUnit
        ) {
            const msg = formatMessage => {
                return formatMessage(
                    {
                        id:
                            minDaysPerUnit > 1
                                ? SchoolLocale.UnitPlanValidMinimumDays
                                : SchoolLocale.UnitPlanValidMinimumDay
                    },
                    { value: minDaysPerUnit }
                );
            };
            if (!unit.error) {
                unit.error = {};
            }
            // //if error cause by other dataIndex, we should not replace it
            // if (!unit.error[dataIndex]) {
            unit.error[dataIndex] = msg;
            unit.error[`${dataIndex}-source`] = dataIndex;
            // }
            needRerender = true;
            preHasError = true;
        } else {
            needRerender =
                needRerender || (unit.error && unit.error[dataIndex] != null);
            if (!preHasError) {
                if (!unit.error) {
                    unit.error = {};
                }
                if (unit.error[`${dataIndex}-source`] == dataIndex) {
                    unit.error[dataIndex] = null;
                    unit.error[`${dataIndex}-source`] = null;
                }
            }
        }
    }
    if (
        !validUnit(pre) &&
        !validUnit(next) &&
        unit.error &&
        unit.error[dataIndex]
    ) {
        if (!unit.error) {
            unit.error = {};
        }
        if (unit.error[`${dataIndex}-source`] == dataIndex) {
            unit.error[dataIndex] = null;
            unit.error[`${dataIndex}-source`] = null;
        }
        needRerender = true;
    }
    return needRerender;
}
function getDefaultStartDate(dataSource, unit, minDaysPerUnit) {
    const preUnits = dataSource.filter(
        item => item.unit < unit && item["startDate"]
    );
    const pre = preUnits && preUnits[preUnits.length - 1];
    return validDate({
        valid: () => minDaysPerUnit && pre,
        date: () => pre["startDate"],
        format: date => moment(date, "YYYY/MM/DD").add(minDaysPerUnit, "day")
    });
}
function unitStartDateChanged(startDate, reRenderHandler, units, unit, school) {
    const cur = units.find(item => item.unit == unit);
    if (!cur) {
        return;
    }
    if (startDate) {
        let lockState =
            cur.lockState == null ? school.appLockState : cur.lockState;
        cur.lockState4Display = lockState == AppLockState.Lock;
        cur.appLockStateDisabled =
            school.defaultAppLockState == AppLockState.Lock &&
            isFutureDate(startDate);
        if (cur.appLockStateDisabled && cur.lockState == AppLockState.Unlock) {
            cur.lockState = AppLockState.Lock;
            cur.lockState4Display = true;
            lockState = AppLockState.Lock;
        }
        if (cur.lockState == null) {
            cur.lockState = lockState;
        }
        cur.appContentStatus = ClassHelper.getUnitAppContentStatus(
            lockState,
            school.appUnlockDays,
            startDate
        );
        if (cur.error && typeof cur.error.startDate === "function") {
            cur.error["startDate"] = null;
        }
    } else {
        cur.lockState = null;
        cur.lockState4Display = null;
    }
    reRenderHandler && reRenderHandler();
}
function unitLockModeChanged(
    locked: boolean,
    reRenderHandler,
    units,
    unit,
    school
) {
    const cur = units.find(item => item.unit == unit);
    if (!cur) {
        return;
    }
    cur.lockState = locked ? AppLockState.Lock : AppLockState.Unlock;
    cur.lockState4Display = locked;
    cur.appContentStatus = ClassHelper.getUnitAppContentStatus(
        cur.lockState,
        school.appUnlockDays,
        cur.startDate
    );
    reRenderHandler && reRenderHandler();
}
function getDefaultUnlockDate(dataSource, unit) {
    const currentUnit = dataSource.find(
        item => item.unit == unit && item["startDate"]
    );
    return currentUnit && currentUnit["startDate"]
        ? moment(currentUnit["startDate"], "YYYY/MM/DD")
        : null;
}
@connect(({ schoolClass: { unit } }: StateType) => ({
    unit
}))
class CurrentUnit extends Component<{ unit?, unitPath?: string }> {
    render() {
        return (
            <div className="unit-info current">
                <FormattedMessage id={SchoolLocale.UnitPlanCurrent} />
                {
                    this.props.unit && this.props.unitPath ?
                        <a href={this.props.unitPath}>{this.props.unit}</a> :
                        <span>{
                            this.props.unit || GLGlobal.intl.formatMessage({
                                id: SchoolLocale.TextNone
                            })
                        }</span>
                }
            </div>
        );
    }
}
@connect(
    ({
         schoolClass: {
             appUnlockUnit,
             model: { curriculumType }
         },
         resource
     }: StateType) => ({
        appUnlockUnit,
        curriculumType,
        name: getClassName(resource)
    })
)
class LockedUnit extends Component<{ appUnlockUnit?; curriculumType?; name? }> {
    render() {
        const { appUnlockUnit, curriculumType, name } = this.props;
        const unitplanLockedId =
        curriculumType == CurriculumType.GrapeSEED
                ? SchoolLocale.UnitPlanLocked
                : SchoolLocale.UnitPlanLocked4LS;
        return (
            <div className="unit-info">
                {appUnlockUnit && (
                    <FormattedMessage
                        id={unitplanLockedId}
                        values={{ unit: appUnlockUnit, name }}
                    />
                )}
            </div>
        );
    }
}

function getClassName({ breadcrumbs }: any) {
    return ((breadcrumbs || {}).schoolClass || {}).name;
}
