import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Table, Icon } from 'antd-min';
import { GLUtil } from 'gl-commonui';
import { SchoolLocale } from '@app/locales/localeid';
import { UnitPlanProgressionHistoryModel } from '@app/service/class';
import { fmtMsg, DateHelper } from '@app/util';
import { FormattedMessage } from 'react-intl';
import { PathConfig } from '@app/config/pathconfig';

export interface IUnitPlanHistoryProps {
    visible: boolean
    progressionHistories: UnitPlanProgressionHistoryModel[]
    routeParams: any
    onLoadUnitPlanHistory: (e) => void
}

export const UnitPlanHistory = (props: IUnitPlanHistoryProps) => {
    const { visible, progressionHistories, routeParams, onLoadUnitPlanHistory } = props;
    return (
        progressionHistories && progressionHistories.length > 0 && 
            <div className='unit-plan-history'>
                <div className='unit-plan-history-title'>
                    <a onClick={onLoadUnitPlanHistory} >
                        <FormattedMessage id={SchoolLocale.UnitPlanProgressionHistory}/>
                        {visible ? <Icon type="up" /> : <Icon type="down" />}
                    </a>
                </div>
                {visible && <Table
                    rowKey={'index'}
                    columns={getColumns(routeParams)}
                    dataSource={progressionHistories}
                    pagination={false}
                    className='unit-plan-history-data'                
                />}
            </div>
    )
}

function getColumns({regionId, schoolId}) {
    return [
        {
            title: fmtMsg({ id: SchoolLocale.UnitPlanProgressionHistoryUnitColumn }),
            dataIndex: "unit",
            width: "10%",
            render: (text, record, index) => {
                return record.schoolClassIsLittleSEED ? `LS${text}` : text;
            }
        },
        {
            title: fmtMsg({ id: SchoolLocale.UnitPlanProgressionHistoryUnitStartDateColumn }),
            dataIndex: "unitStartDate",
            width: "20%",
            render: (text, record, index) => {
                return text ? DateHelper.formatDate2Local(text) : '';
            }
        },        
        {
            title: fmtMsg({ id: SchoolLocale.UnitPlanProgressionHistoryCampusNameColumn }),
            dataIndex: "campusName",
            width: "25%",
            render: (text, record, index) => {
                const campusId = record.campusId;
                const pathClasses = regionId && schoolId && campusId ? GLUtil.pathStringify(PathConfig.Classes, { regionId, schoolId, campusId }) : null;
                return pathClasses ? 
                    <Link to={pathClasses}>{text}</Link> : <span>{text}</span>
            }
        },
        {
            title: fmtMsg({ id: SchoolLocale.UnitPlanProgressionHistoryClassNameColumn }),
            dataIndex: "schoolClassName",
            width: "25%",
            render: (text, record, index) => {
                const campusId = record.campusId;
                const pathStudents = regionId && schoolId && campusId && record.schoolClassId ? GLUtil.pathStringify(PathConfig.Students, { regionId, schoolId, campusId, classId: record.schoolClassId }) : null;
                return pathStudents ? 
                    <Link to={pathStudents}>{text}</Link> : <span>{text}</span>
            }
        },
        {
            title: fmtMsg({ id: SchoolLocale.UnitPlanProgressionHistoryClassStartDateColumn }),
            dataIndex: "schoolClassStartDate",
            width: "20%",
            render: (text, record, index) => {
                return text ? DateHelper.formatDate2Local(text) : '';
            }
        },
    ]
}